<template>
	<div id="mainContainer" class="mainContainer">
		<div id="bingoContainer" class="kenoContainer">
		<div class="containerLeft">
			<div id="divSoundControl" class="divSoundControl iconSoundInactive">
				<button class="btnSoundControl" @click="enableSound">
				</button>
			</div>
			<div class="bigTubeContainer CenteredY">
				<div class="bigBackTube"></div>
				<div id="bigBallMain" class="bigBallMain">
					<div
							id="bigNumberBall"
							class="textBigBall"
						>
							{{idBall}}
					</div>
				</div>
				<div class="bigFromTube CenteredX"></div>
				<div class="ballNumberContainer">
					<div v-if="!showMessage">Bola</div>
					<div
						v-if="!showMessage"
						class="fontSize5"
					>
						{{currentIndexBall + 1}}
					</div>
					<div
						v-if="showMessage"
						class="fontSize3"
					>
						SORTEIO<br />CONCLUÍDO
					</div>
				</div>
				<div class="bigTubeFromContainer"></div>
			</div>
		</div>
		<div class="containerRight">
			<div class="bolilleroContainer">
				<div class="smallTubeContainer">
					<div class="smallBackTube"></div>
					<div id="smallBallMain" class="smallBallMain">
						<div
							id="smallNumberBall"
							class="textSmallBall"
						>
							{{idBall}}
						</div>
					</div>
					<div class="smallFromTube CenteredX"></div>
				</div>
				<div class="ballsContainer">
					<div
						class="smallBall"
						v-for="index in totalBalls"
						v-bind:key="index"
						:id="'smallBall-'+index"
					>
						<div
							:id="'smallNumber-'+index"
							class="textSmallBall"
						>
								{{idBall}}
						</div>
					</div>
				</div>
				<div class="bolilleroFromContainer"></div>
			</div>
		</div>
	</div>
	<div class="raffleInfo">
		<div id="logoGame" class="logoGame"></div>
		<div class="titilliumRegularFont">Inicio de Sorteio: {{raffleStartTime}}</div>
	</div>
	</div>
</template>

<script>
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
/* eslint-disable no-plusplus */
import '../css/main.css';
import '../css/bingoNew.css';
import '../css/general.css';
import '../css/animate.min.css';
import {
	TweenLite,
	Bounce,
	Sine,
} from 'gsap';
import constants from '../constants';

export default {
	name: 'Keno',
	props: ['drawData'],
	data() {
		return {
			ballsInBoard: [],
			ballsVisibleOnhistory: 0,
			callbackPositionId: null,
			completedTimeoutId: null,
			completedSmallBallId: null,
			currentIndexBall: 0,
			data: '',
			enableSounds: false,
			idBall: '',
			initStatusSounds: false,
			isIOS: false,
			newBalldId: null,
			raffleData: [],
			raffledBalls: [],
			raffleStartTime: '',
			resultInBoardId: null,
			showMessage: false,
			timelineLite: null,
			totalBalls: 20,
			typeGame: '',
			urlImage: '',
			ballPositionsMap: [
				{ pos: { x: '86%', y: '52%' }, level: 3, rot: 2160 },
				{ pos: { x: '72%', y: '51%' }, level: 3, rot: 1800 },
				{ pos: { x: '58%', y: '50%' }, level: 3, rot: 1440 },
				{ pos: { x: '44%', y: '49%' }, level: 3, rot: 1080 },
				{ pos: { x: '30%', y: '48%' }, level: 3, rot: 720 },
				{ pos: { x: '16%', y: '47%' }, level: 3, rot: 360 },
				{ pos: { x: '2%', y: '45%' }, level: 3, rot: 0 },
				{ pos: { x: '2%', y: '30%' }, level: 2, rot: -2160 },
				{ pos: { x: '16%', y: '28%' }, level: 2, rot: -1800 },
				{ pos: { x: '30%', y: '27%' }, level: 2, rot: -1440 },
				{ pos: { x: '44%', y: '26%' }, level: 2, rot: -1080 },
				{ pos: { x: '58%', y: '25%' }, level: 2, rot: -720 },
				{ pos: { x: '72%', y: '24%' }, level: 2, rot: -360 },
				{ pos: { x: '86%', y: '23%' }, level: 2, rot: 0 },
				{ pos: { x: '86%', y: '8%' }, level: 1, rot: 2160 },
				{ pos: { x: '72%', y: '5%' }, level: 1, rot: 1800 },
				{ pos: { x: '58%', y: '4%' }, level: 1, rot: 1440 },
				{ pos: { x: '44%', y: '3%' }, level: 1, rot: 1080 },
				{ pos: { x: '30%', y: '2%' }, level: 1, rot: 720 },
				{ pos: { x: '16%', y: '1%' }, level: 1, rot: 360 },
			],
		};
	},
	beforeMount() {
		this.resetView();
		if (this.drawData) {
			this.data = this.drawData;
			this.raffleData = this.drawData.raffled;
			this.typeGame = this.drawData.gameType;
			this.totalBalls = this.drawData.totalBallsToDraw;
			const time = this.data.timestamp.split(' ');
			this.raffleStartTime = time && time[1];
			this.isIOS = this.$store.getters.isIOS;
		}
		this.loadImage();
	},
	mounted() {
		this.loadImage();
		this.loadSounds();
		this.startDraw();
	},
	beforeDestroy() {
		this.resetView();
	},
	methods: {
		startDraw() {
			const startGameSound = this.getSound('startGame');
			this.playSound(startGameSound);
			this.notifyStatus();

			// Check Raffle start date
			if (!this.data.recovery) {
				// Draw already started
				this.newBall();
			} else {
				this.recoveryDraw();
			}
		},
		recoveryDraw() {
			const balls = this.raffleData;
			const estimatedTimePerBall = 4100;
			const timeForShowIdle = 20000;
			const now = Date.now();
			// const raffleTime = Date.parse(this.data.raffleTimestamp);
			const raffleTime = this.data.raffleParseTimestamp;
			let raffledBalls = Math.floor((now - raffleTime) / estimatedTimePerBall);

			if ((now - raffleTime) > (estimatedTimePerBall * this.raffleData.length) + timeForShowIdle) {
				this.$store.dispatch('drawCompleted');
				return;
			}

			// eslint-disable-next-line max-len
			raffledBalls = (raffledBalls >= this.raffleData.length) ? this.raffleData.length : raffledBalls;
			for (let i = 0; i < raffledBalls; i++) {
				let idBall = balls[i];
				idBall = (idBall < 10) ? `0${idBall}` : idBall;
				this.idBall = idBall;
				this.currentIndexBall = i;
				this.raffledBalls.push(String(idBall));

				this.showBallInBolillero(idBall, i);
			}
			this.data.recovery = false;
			this.newBall();
		},
		newBall() {
			if (this.raffledBalls.length >= this.raffleData.length) {
				this.drawCompleted();
				return;
			}

			const index = this.raffledBalls.length;
			let idBall = this.raffleData[index];
			if (idBall < 10) { idBall = `0${idBall}`; }

			this.raffledBalls.push(String(idBall));

			this.idBall = idBall;
			this.currentIndexBall = index;
			this.updateNumberBall(idBall);
			// Start Animations
			this.getInBigBallAnimation(idBall);
			this.getInSmallBallAnimation(idBall);

			TweenLite.delayedCall(3, this.getOutBigBallAnimation);
		},
		showBallInBolillero(idBall, index) {
			const nameId = `smallBall-${index + 1}`;
			const textId = `smallNumber-${index + 1}`;
			const ballContainer = document.getElementById(nameId);
			const textSmallBall = document.getElementById(textId);
			if (ballContainer && textSmallBall) {
				textSmallBall.innerText = idBall;
				this.showBallWithoutAnimation(ballContainer, index);
			}
		},
		drawCompleted() {
			this.showMessage = true;
			const endGameSound = this.getSound('endGame');
			this.playSound(endGameSound);
			this.notifyStatus();

			const timeForShowIdle = 10000;
			this.completedTimeoutId = setTimeout(() => {
				clearTimeout(this.completedTimeoutId);
				this.completedTimeoutId = null;

				if (this.raffledBalls.length >= this.raffleData.length) {
					this.$store.dispatch(constants.DRAW_COMPLETED);
				}
			}, timeForShowIdle);
		},
		notifyStatus() {
			let status = (this.raffledBalls.length === 0) ? constants.BINGO_STARTED : constants.BINGO_RAFFLING;
			status = (this.raffledBalls.length >= this.raffleData.length) ? constants.BINGO_COMPLETED : status;

			const eventData = {
				type: constants.STATE_APP,
				data: {
					status,
					gameType: this.typeGame,
					currentBall: this.raffledBalls.length,
					totalBalls: this.raffleData.length,
					raffledBalls: this.raffledBalls,
					timestamp: this.data.timestamp,
				},
			};

			this.$store.dispatch(constants.STATUS_CHANGED, eventData);
		},
		resetView() {
			this.killAllAnimations();
			clearTimeout(this.completedTimeoutId);
			clearTimeout(this.completedSmallBallId);
			this.completedTimeoutId = null;
			this.completedSmallBallId = null;
			this.currentIndexBall = 0;
			this.raffleData = [];
			this.raffledBalls = [];
			this.data = '';
			this.idBall = '';
			this.raffleStartTime = '';
			this.typeGame = '';
			this.urlImage = '';
		},
		loadImage() {
			try {
				this.urlImage = require(`../assets/image/balls/${this.typeGame}_ball.png`);
			} catch (error) {
				this.urlImage = require('../assets/image/balls/7777_ball.png');
			}

			const smallBall = document.getElementsByClassName('smallBall');
			const smallBallMain = document.getElementById('smallBallMain');
			const bigBallMain = document.getElementById('bigBallMain');
			const back = document.getElementById('mainContainer');
			const logo = document.getElementById('logoGame');
			const imageBall = `ball_${this.typeGame}`;
			const backColor = `background_${this.typeGame}`;
			const logoImage = `logo_${this.typeGame}`;
			const colorText = `colorText_${this.typeGame}`;

			if (smallBallMain && bigBallMain && back && logo) {
				smallBallMain.classList.add(imageBall);
				smallBallMain.classList.add(colorText);
				bigBallMain.classList.add(imageBall);
				bigBallMain.classList.add(colorText);
				back.classList.add(backColor);
				logo.classList.add(logoImage);
			}

			smallBall.forEach((ball) => {
				ball.classList.add(imageBall);
				ball.classList.add(colorText);
			});
		},

		loadSounds() {
			const urlHitBall = this.getSound('hitBall');
			const urlMoveBall = this.getSound('moveBall');
			const urlMoveShortBall = this.getSound('moveShortBall');
			this.initStatusSounds = this.$store.getters.getStatusSounds;

			this.sound_hitBall = urlHitBall || null;
			this.sound_moveBall = urlMoveBall || null;
			this.sound_moveShortBall = urlMoveShortBall || null;

			if (this.initStatusSounds !== this.enableSounds) {
				this.enableSound();
			}
		},

		enableSound() {
			const soundControl = document.getElementById('divSoundControl');

			if (this.enableSounds) {
				this.enableSounds = false;
				soundControl.classList.remove('iconSoundActive');
				soundControl.classList.add('iconSoundInactive');
			} else {
				this.enableSounds = true;
				soundControl.classList.remove('iconSoundInactive');
				soundControl.classList.add('iconSoundActive');
			}

			this.$store.dispatch('statusChangeSounds', this.enableSounds);

			const AudioContext = window.AudioContext || window.webkitAudioContext;
			if (AudioContext) {
				// const audioCtx = new AudioContext();
				const genericSound = this.getSound('button_generic', 0.5);
				this.playSound(genericSound);
			}
		},

		playSound(audio) {
			if (audio && this.enableSounds) {
				audio.play();
			}
		},

		getSound(nameSound, volume) {
			let urlSound = null;
			let audio = null;
			try {
				urlSound = require(`../assets/sounds/keno/${nameSound}.mp3`);
			} catch (error) {
				console.log('Error loading sound');
			}

			if (urlSound) {
				const volumeLevel = volume || 1;
				audio = new Audio(urlSound);
				audio.volume = volumeLevel;
			}

			return audio;
		},
		updateNumberBall(idBall) {
			const textSmallBall = `smallNumber-${this.currentIndexBall + 1}`;
			const smallNumber = document.getElementById(textSmallBall);
			const textBall = document.getElementById('smallNumberBall');
			const textBigBall = document.getElementById('bigNumberBall');

			if (textBall && smallNumber) {
				textBall.innerText = idBall;
				textBigBall.innerText = idBall;
				smallNumber.innerText = idBall;
			}
		},
		getLevel() {
			const index = this.currentIndexBall;
			const map = this.ballPositionsMap[index];

			return map.level;
		},
		useRAF() {
			// The animations keep running without focus
			TweenLite.ticker.useRAF(false);
			TweenLite.lagSmoothing(0);
		},
		// ***** ANIMATIONS ***** /
		getInBigBallAnimation(idBall) {
			const ballContainer = document.getElementById('bigBallMain');
			if (ballContainer) {
				const sound = this.getSound(idBall);
				const callback = () => {
					if (sound) {
						this.playSound(sound);
						// dispatch status event
						this.notifyStatus();
					}
				};

				this.useRAF();
				TweenLite.to(ballContainer, 1, {
					top: '10%',
					onComplete: callback,
				});
			}
		},
		getOutBigBallAnimation() {
			const ballContainer = document.getElementById('bigBallMain');
			TweenLite.killTweensOf(this.getOutBigBallAnimation);

			if (ballContainer) {
				const callback = () => {
					ballContainer.style.top = '-100%';
					this.newBall();
				};

				this.useRAF();
				TweenLite.to(ballContainer, 1, {
					top: '100%',
					onComplete: callback,
				});
			}
		},
		getInSmallBallAnimation() {
			const ballContainer = document.getElementById('smallBallMain');
			const nameId = `smallBall-${this.currentIndexBall + 1}`;
			const smallBallContainer = document.getElementById(nameId);

			if (ballContainer && smallBallContainer) {
				const level = this.getLevel();
				smallBallContainer.index = this.currentIndexBall;

				const callback = () => {
					if (this.sound_hitBall && this.enableSounds) {
						this.sound_hitBall.play();
					}

					this.completedSmallBallId = setTimeout(() => {
						clearTimeout(this.completedSmallBallId);
						this.completedSmallBallId = null;

						ballContainer.style.top = '-100%';
						smallBallContainer.style.visibility = 'visible';
						if (level === 1) {
							this.movementEndBall(smallBallContainer);
						} else {
							this.movementBallLevel1(smallBallContainer);
						}
					}, 500);
				};

				this.useRAF();
				TweenLite.to(ballContainer, 1, {
					ease: Bounce.easeInOut,
					top: '58%',
					onComplete: callback,
				});
			}
		},
		movementBallLevel1(ball) {
			if (!ball) return;

			const callback = () => {
				TweenLite.killTweensOf(ball);
				this.movementDownLevel1(ball);
			};

			this.useRAF();
			TweenLite.to(ball, 2, {
				ease: Sine.inOut,
				left: '86%',
				top: '6%',
				directionalRotation: '2160_cw',
				transformOrigin: '50% 50%',
				onComplete: callback,
			});

			if (this.sound_moveBall && this.enableSounds) {
				this.sound_moveBall.play();
			}
		},
		movementBallLevel2(ball) {
			if (!ball) return;
			const callback = () => {
				TweenLite.killTweensOf(ball);
				this.movementDownLevel2(ball);
			};

			this.useRAF();
			TweenLite.to(ball, 2, {
				ease: Sine.inOut,
				left: '2%',
				top: '30%',
				directionalRotation: '0_ccw',
				transformOrigin: '50% 50%',
				onComplete: callback,
			});
			if (this.sound_moveBall && this.enableSounds) {
				this.sound_moveBall.play();
			}
		},
		movementEndBall(ball) {
			if (!ball) return;

			// const index = this.currentIndexBall;
			const { index } = ball;
			const map = this.ballPositionsMap[index];
			const position = map.pos;
			let timeAnimation = 0.5;

			const callback = () => {
				TweenLite.killTweensOf(ball);
			};

			const animParams = {
				ease: 'none',
				top: position.y,
				left: position.x,
				onComplete: callback,
			};

			if (map.rot !== 0) {
				animParams.rotation = map.rot;
				// eslint-disable-next-line operator-assignment
				timeAnimation = (animParams.rotation / 360) * timeAnimation;
				timeAnimation = (timeAnimation >= 0) ? timeAnimation : timeAnimation * -1;
			}

			this.useRAF();
			TweenLite.to(ball, timeAnimation, animParams);
			if (this.sound_moveShortBall && this.enableSounds) {
				this.sound_moveShortBall.play();
			}
		},
		showBallWithoutAnimation(ball) {
			const index = this.currentIndexBall;
			const map = this.ballPositionsMap[index];
			const position = map.pos;
			const ballContainer = ball;

			if (ballContainer && position) {
				ballContainer.style.top = position.y;
				ballContainer.style.left = position.x;
				ballContainer.style.visibility = 'visible';
			}
		},
		movementDownLevel1(ball) {
			if (!ball) return;

			const callback = () => {
				const level = this.getLevel();
				TweenLite.killTweensOf(ball);

				if (this.sound_hitBall && this.enableSounds) {
					this.sound_hitBall.play();
				}

				if (level > 2) {
					this.movementBallLevel2(ball);
				} else {
					this.movementEndBall(ball);
				}
			};

			this.useRAF();
			TweenLite.to(ball, 0.2, {
				ease: Bounce.easeOut,
				top: '23%',
				onComplete: callback,
			});
		},
		movementDownLevel2(ball) {
			if (!ball) return;

			const callback = () => {
				if (this.sound_hitBall && this.enableSounds) {
					this.sound_hitBall.play();
				}

				this.movementEndBall(ball);
			};

			this.useRAF();
			TweenLite.to(ball, 0.2, {
				ease: Bounce.easeOut,
				top: '45%',
				onComplete: callback,
			});
		},
		killAllAnimations() {
			const bigBallMain = document.getElementById('bigBallMain');
			const smallBallMain = document.getElementById('smallBallMain');
			const smallBall = document.getElementsByClassName('smallBall');

			if (bigBallMain) {
				TweenLite.killTweensOf(bigBallMain);
				bigBallMain.style.top = '-100%';
			}

			if (smallBallMain) {
				TweenLite.killTweensOf(smallBallMain);
				smallBallMain.style.top = '-100%';
			}

			if (smallBall) {
				smallBall.forEach((ball) => {
					TweenLite.killTweensOf(ball);
					ball.style.top = '';
					ball.style.left = '';
					ball.style.visibility = 'hidden';
				});
			}
		},
	},
};
</script>
