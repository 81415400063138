<template>
	<div
		class="w100 h100 animate__animated animate__fadeIn"
	>
		<IdleApp
			v-if="showIdle"
		/>
		<Keno
			v-if="showDraw && gameType !== 8888"
			v-bind:drawData="newRaffleData"
			:key="keyNewRaffle"
		/>
		<TimeCerto
			v-else-if="showDraw && newRaffleData && gameType === 8888"
			v-bind:drawData="newRaffleData"
			:key="keyNewRaffle"
		/>
	</div>
</template>

<script>
/* eslint-disable no-console */
import '../css/general.css';
import '../css/main.css';
import '../css/animate.min.css';
import Keno from '../components/keno.vue';
import TimeCerto from '../components/timeCerto.vue';
import IdleApp from '../components/Idle.vue';

export default {
	name: 'Standalone',
	components: {
		IdleApp,
		Keno,
		TimeCerto,
	},
	data() {
		return {
		};
	},
	computed: {
		newRaffleData() {
			return this.$store.getters.startRaffle;
		},
		gameType() {
			return this.$store.getters.startRaffle.gameType;
		},
		showDraw() {
			return this.$store.getters.showDraw;
		},
		showIdle() {
			return (!this.$store.getters.showDraw && !this.$store.getters.startRaffle);
		},
		keyNewRaffle() {
			return this.$store.state.startRaffle.raffleTimestamp;
		},
	},
	methods: {
	},
};
</script>
