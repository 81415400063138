import { render, staticRenderFns } from "./ultimoSorteioTimeCerto.vue?vue&type=template&id=a8004482&"
import script from "./ultimoSorteioTimeCerto.vue?vue&type=script&lang=js&"
export * from "./ultimoSorteioTimeCerto.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports