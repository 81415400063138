<template>
	<div id="bingoPresenterIdle" class="w100 h100 roundedBorders animate__animated animate__zoomIn">
		<div id="ballDrawContainer" class="pb-2 h100">
			<div class="d-flex">
				<v-col cols="7" class="h50 d-flex pt-1 pl-2 pr-0">
					<div class="textSubTitle colorSubTitle">Último sorteio</div>
				</v-col>
				<v-col cols="5" class="h50 d-flex text-center align-center pt-0 pb-1">
					<v-img class="logo" :src="urlGameLogo" contain></v-img>
				</v-col>
			</div>
			<v-col cols="12" :class="'h100 mt-0 pt-0 mb-0 ' + gridStyle">
				<div
					id="boardContainer"
					class="boardContainer"
					v-for="(ball, index) in raffleData"
					v-bind:key="index"
					>
						<div id="bingoBallIdle"
							class="idleBallLastDraw d-flex justify-center pt-0 mt-0"
						>
							<v-img :id="'idleBall-' + index" :src='urlImage'
								transition="scale-transition"
							>
								<div :id="'idleNumber-'+index"
									:class="'d-flex justify-center textBall ' + textStyle"
								>
									{{ball}}
								</div>
							</v-img>
						</div>
				</div>
			</v-col>
		</div>
	</div>
</template>

<script>
/* eslint-disable vue/no-parsing-error */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
/* eslint-disable no-plusplus */
import '../css/main.css';
import '../css/sorteoAnterior.css';
import '../css/animate.min.css';

export default {
	name: 'UltimoSorteio',
	props: ['drawData'],
	data() {
		return {
			data: '',
			gridStyle: '',
			textStyle: '',
			urlImage: '',
			typeGame: '',
			raffleData: [],
		};
	},
	beforeMount() {
		this.data = this.drawData;
		this.raffleData = this.drawData.raffled;
		this.typeGame = this.drawData.gameType;
		try {
			this.urlImage = require(`../assets/image/balls/${this.typeGame}_ball.png`);
			this.urlGameLogo = require(`../assets/logos/${this.typeGame}.png`);
		} catch (error) {
			this.urlImage = require('../assets/image/balls/idle_ball.png');
			this.urlGameLogo = '';
		}
		if (this.typeGame === 7777) {
			this.gridStyle = 'kenoGrid';
			this.textStyle = 'kenoTextBall';
		} else if (this.typeGame === 6666) {
			this.gridStyle = 'senaGrid';
			this.textStyle = 'senaTextBall';
		} else {
			this.gridStyle = 'quinaGrid';
			this.textStyle = 'quinaTextBall';
		}
	},
	methods: {
	},
};
</script>
