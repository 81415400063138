<template>
	<div class="mainContainerTimeCerto">
		<div class="timeCertoContainer">
			<div id="rodaDaFortuna" class="rodaDaFortuna"></div>
		</div>
		<div id="mainMessageTitle" class="error">
			<div id="mainMessageTitleText" class="error">
				{{errorMessage}}
			</div>
		</div>
		<div class="raffleInfoTC">
			<div id="logoTimeCerto" class="logoTimeCerto"></div>
			<div class="titilliumRegularFont textAlignLeft">Inicio de Sorteio: {{raffleStartTime}}</div>
		</div>
	</div>
</template>

<script>
import constants from '../constants';
import '../css/timeCerto.css';

export default {
	name: 'timeCerto',
	props: ['drawData'],
	data() {
		return {
			data: this.drawData,
			errorMessage: '',
			show: true,
			raffleStartTime: '',
			completedTimeoutId: null,
		};
	},
	beforeDestroy() {
		this.destroyRoda();
	},
	mounted() {
		this.data = this.drawData;

		// const raffleTime = Date.parse(this.data.raffleTimestamp);
		const raffleTime = this.data.raffleParseTimestamp;
		const now = Date.now();
		const timeAnimation = 21000;
		const timeForShowIdle = 15000;
		const time = this.data.timestamp.split(' ');
		this.raffleStartTime = time && time[1];

		const dataRaffle = {
			raffled: this.data.raffled,
		};

		if ((now - raffleTime) >= timeAnimation) {
			dataRaffle.animated = false;
			dataRaffle.animationTime = 0;

			if ((now - raffleTime) >= timeAnimation + timeForShowIdle) {
				this.$store.dispatch('drawCompleted');
				return;
			}
		}

		const statusSounds = this.$store.getters.getStatusSounds;
		window.initStatusSounds = statusSounds;

		this.startDraw(dataRaffle);
		this.drawCompleted(dataRaffle.animationTime);
	},
	methods: {
		startDraw(dataRaffle) {
			window.criarRoda(dataRaffle);
			this.notifyStatus(constants.RODA_STARTED);
		},
		drawCompleted(time) {
			const animationTime = time || 21000;
			const timeForShowIdle = 10000;
			const timeout = animationTime + timeForShowIdle;

			this.completedTimeoutId = setTimeout(() => {
				clearTimeout(this.completedTimeoutId);
				this.completedTimeoutId = null;

				this.$store.dispatch(constants.DRAW_COMPLETED);
				this.notifyStatus(constants.RODA_COMPLETED);
			}, timeout);
		},
		destroyRoda() {
			const enableSound = window.initStatusSounds;
			this.$store.dispatch('statusChangeSounds', enableSound);

			if (this.completedTimeoutId) {
				clearTimeout(this.completedTimeoutId);
			}

			window.destroyRoda();
			window.muteAll();
		},
		notifyStatus(status) {
			const eventData = {
				type: constants.STATE_APP,
				data: {
					status,
					gameType: this.data.gameType,
					result: this.data.raffled,
					timestamp: this.data.timestamp,
				},
			};

			this.$store.dispatch(constants.STATUS_CHANGED, eventData);
		},
	},
};
</script>
