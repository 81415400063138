<template>
	<div class="w100 h100 absoluteDiv">
		<div class="rodaDaFortunaContainer" style="overflow: hidden">
			<div id="rodaDaFortunaUltimo"  class="w100 h100" style="overflow: hidden"></div>
		</div>
		<div id="mainMessageTitle" class="error">
			<div id="mainMessageTitleText" class="error">
				{{errorMessage}}
			</div>
		</div>
	</div>
</template>

<script>
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
/* eslint-disable no-plusplus */
/* eslint-disable no-console */
import '../css/sorteoAnterior.css';

export default {
	name: 'ultimoSorteioTimeCerto',
	props: ['drawData'],
	data() {
		return {
			data: this.drawData,
			errorMessage: '',
			show: true,
		};
	},
	mounted() {
		this.startDraw();
	},
	methods: {
		startDraw() {
			const dataRaffle = {
				idContainer: 'rodaDaFortunaUltimo',
				raffled: this.data.raffled,
				animated: false,
				animationTime: 0,
			};

			window.criarRoda(dataRaffle);

			setTimeout(() => {
				// const div = document.getElementById('rodaDaFortunaUltimo');
				// div.setAttribute('id', 'rodaDaFortunaHistory');
				window.APP_RODA = [];
			}, 1000);
		},
	},
};
</script>
