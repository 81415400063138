<template>
	<v-app p0>
		<v-app-bar
			app
			color="black"
			dark
			v-if="!standaloneMode"
		>
			<div class="d-flex align-center">
				<v-img
					alt="Vibra Logo"
					class="shrink mr-2"
					contain
					src="./assets/logos/logoVibra.png"
					transition="scale-transition"
					width="160"
				/>
			</div>
		</v-app-bar>
		<v-main>
			<TvView v-if="dependenciesLoaded && !standaloneMode" />
			<Standalone v-if="dependenciesLoaded && standaloneMode" />
		</v-main>
		<v-footer
			absolute
			dark
			class="vibraGradient"
			v-if="!standaloneMode"
			> </v-footer>
	</v-app>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable vue/script-indent */
import TvView from './view/tvView.vue';
import Standalone from './view/standalone.vue';
import constants from './constants';

export default {
	name: 'App',
	components: {
		TvView,
		Standalone,
	},
	data() {
		return {
			dependenciesLoaded: false,
			dependenciesTimeoutID: null,
			standaloneMode: true,
		};
	},
	mounted() {
		this.initializeApp();
		this.getDependenciesLoaded();
	},
	methods: {
		getLiveSorteos() {
			this.$store.dispatch(constants.GET_SORTEIO_LIVE);
		},
		getProximosSorteio() {
			this.$store.dispatch(constants.GET_PROXIMOS_SORTEIOS);
		},
		initializeApp() {
			this.$store.dispatch(constants.INITIALIZE_APP);
			this.setInitializeState();
		},
		getDependenciesLoaded() {
			this.dependenciesLoaded = (window.assetManager && window.assetManager.assetsLoaded);

			if (!this.dependenciesLoaded) {
				this.dependenciesTimeoutID = setTimeout(() => {
					clearTimeout(this.dependenciesTimeoutID);
					this.dependenciesTimeoutID = null;

					this.getDependenciesLoaded();
				}, 1000);
			} else {
				clearTimeout(this.dependenciesTimeoutID);
				this.dependenciesTimeoutID = null;
			}
		},
		setInitializeState() {
			const eventData = {
				type: constants.STATE_APP,
				data: { status: constants.INITIALIZED_APP },
			};

			this.$store.dispatch(constants.STATUS_CHANGED, eventData);
		},
	},
};
</script>
