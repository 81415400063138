/* eslint-disable no-plusplus */
export default class CommManager {
	constructor() {
		this.initialized = false;
		this.store = null;

		// Set values
		this.liveRaffleData = null;
		this.upcomingRafflesData = null;

		// Set parsed value of upcoming draws
		this.currentDraw = null;
		this.previousDraw = null;
		this.recentUpcomingDraws = [];

		// Saves old draws
		this.oldDrawsRaffled = [];

		// Set listeners and timers
		this.listenerLiveRaffleData = null;
		this.listenerUpcomingRafflesData = null;
		this.timerLiveRaffle = 2000;
		this.timerUpcomingRaffles = 60000;

		// Connection DATA
		this.systemId = 15;
		this.clientId = 99; // Prod: 2848; // Develop: 92 - 99
		this.gameType = null;
		this.urlServices = 'https://extapp.gwsvc.de/api/ApuracaoRodada/Sorteios';
		this.authData = JSON.stringify({
			'Sistema_ID': this.systemId,
			'Cliente_ID': this.clientId,
		});

		// Data Games
		this.totalBallsByGameType = {
			8888: 1,
			7777: 20,
			6666: 6,
			5555: 5,
			1515: 15,
		};
	}

	initialize(store) {
		this.initialized = true;
		this.store = store;

		// Start Requests
		this.dispatchRequestLiveRaffle(true);
		this.dispatchRequestUpcomingRaffles(true);
	}

	dispatchRequestLiveRaffle(retryMode) {
		this.listenerLiveRaffleData = setTimeout(() => {
			this.getLiveRaffle()
				.then((returnedData) => {
					if (returnedData) {
						this.liveRaffleData = returnedData;
						this.parseLiveDraws();
					}
				});
		}, (!retryMode) ? this.timerLiveRaffle : 1000);
	}

	dispatchRequestUpcomingRaffles(retryMode) {
		this.listenerUpcomingRafflesData = setTimeout(() => {
			// Get data
			this.getUpcomingRaffles().then((returnedData) => {
				if (returnedData) {
					this.upcomingRafflesData = returnedData;
					this.parseUpcomingDraws();
				}
			});
		}, (!retryMode) ? this.timerUpcomingRaffles : 500);
	}

	async getLiveRaffle() {
		const urlBase = `${this.urlServices}/Live`;
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: this.authData,
			redirect: 'follow',
			cache: 'no-store',
		};

		const returnedData = await fetch(urlBase, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result && result.games) {
					this.dispatchRequestLiveRaffle(false);
					return result;
				}

				// Return error
				console.error('Error /Live >>> Data without games');
				this.dispatchRequestLiveRaffle(true);
				return false;
			})
			.catch((error) => {
				console.error('Error Catch /Live', error);
				this.dispatchRequestLiveRaffle(true);

				return false;
			});

		return returnedData;
	}

	async getUpcomingRaffles() {
		const urlBase = `${this.urlServices}/Proximos`;
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: this.authData,
			redirect: 'follow',
		};

		const returnedData = await fetch(urlBase, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result && result.draws) {
					this.dispatchRequestUpcomingRaffles(false);
					return result.draws;
				}

				// Return error
				console.error('Error /Proximos >>> Data without draws...');
				this.dispatchRequestUpcomingRaffles(true);
				return false;
			})
			.catch((error) => {
				console.error('Error Catch /Proximos', error);
				this.dispatchRequestUpcomingRaffles(true);

				return false;
			});

		return returnedData;
	}

	parseUpcomingDraws() {
		// Definitions
		const parsedData = [];
		const data = this.upcomingRafflesData;
		const currentDate = Date.now();

		// Parse Data and remove the old draws
		for (let games = 0; games < data.length; games++) {
			const game = data[games];

			// Loop every upcoming game
			if (game && game.upcomingDraws) {
				const { gameType, upcomingDraws } = game;

				// Loop every upcoming draw in each game
				for (let indexDraw = 0; indexDraw < upcomingDraws.length; indexDraw++) {
					const draw = upcomingDraws[indexDraw];
					const dateDraw = new Date(draw.timestamp).getTime();

					// Save only the future draws
					if (dateDraw >= currentDate) {
						draw.gameType = gameType;
						parsedData.push(draw);
					}
				}
			}
		}

		// Sort draws
		if (parsedData.length) {
			const sortedData = parsedData.sort((a, b) => {
				const aDate = new Date(a.timestamp).getTime();
				const bDate = new Date(b.timestamp).getTime();
				return aDate - bDate;
			});

			// New upcoming draws?
			let newDraw = false;
			for (let i = 0; i < sortedData.length; i += 1) {
				let found = false;
				for (let j = 0; j < this.recentUpcomingDraws.length && !found; j += 1) {
					if (sortedData[i].gameType === this.recentUpcomingDraws[j].gameType && sortedData[i].timestamp === this.recentUpcomingDraws[j].timestamp) {
						found = true;
					}
				}

				if (!found) {
					newDraw = true;
				}
			}

			// New data for upcoming draws
			if (newDraw) {
				this.store.commit('setUpcomingDrawsParsed', sortedData);
				this.recentUpcomingDraws = sortedData;

				// If there is a current Draw, verify that is not on the list
				if (this.currentDraw) {
					this.removeCurrentDrawFromUpcoming(this.currentDraw);
				}
			}
		} else {
			console.log('Cannot obtain valid data form upcoming matches request');
		}
	}

	parseLiveDraws() {
		// Definitions
		const previousDraws = [];
		const delayServer = 4000;
		// eslint-disable-next-line prefer-destructuring
		const isIOS = this.store.getters.isIOS;
		const data = this?.liveRaffleData?.games;
		let currentDraw = {};
		let sortedData = [];

		// Get Current time
		let serverTime = this.liveRaffleData && this.liveRaffleData?.serverTimestamp;
		if (isIOS) {
			let newFormat = serverTime.split(':', 3).join(':');
			newFormat = newFormat.split(' ').join('T');
			serverTime = Date.parse(newFormat);
		} else {
			serverTime = Date.parse(serverTime);
		}

		// Order every previous draws
		for (let games = 0; games < data.length; games++) {
			const game = data[games];

			if (game && game.previousDraw) {
				const draw = game.previousDraw;
				draw.gameType = game.gameType;
				draw.payTable = game.payTable;
				draw.serverTime = serverTime;
				draw.delayServer = delayServer;

				previousDraws.push(draw);
			}
		}

		// Order matches
		if (previousDraws.length) {
			sortedData = previousDraws.sort((a, b) => {
				let timeA = a.raffleTimestamp;
				let timeB = b.raffleTimestamp;

				if (isIOS) {
					let newFormatTimeA = a.timestamp;
					let newFormatTimeB = b.timestamp;
					newFormatTimeA = newFormatTimeA.split(':', 3).join(':');
					newFormatTimeA = newFormatTimeA.split(' ').join('T');
					newFormatTimeB = newFormatTimeB.split(':', 3).join(':');
					newFormatTimeB = newFormatTimeB.split(' ').join('T');
					timeA = newFormatTimeA;
					timeB = newFormatTimeB;
				}

				const aDate = new Date(timeA).getTime();
				const bDate = new Date(timeB).getTime();
				return bDate - aDate;
			});

			// Check if first draw is on the window to be shown
			currentDraw = sortedData.shift();
		}

		if (!this.currentDraw || (currentDraw?.raffleTimestamp !== this.currentDraw?.raffleTimestamp)) {
			// Check if draw was already shown
			let alreadyRaffled = false;
			for (let i = 0; i < this.oldDrawsRaffled.length && !alreadyRaffled; i++) {
				if (this.oldDrawsRaffled[i].gameType === currentDraw.gameType && this.oldDrawsRaffled[i].timestamp === currentDraw.timestamp) {
					alreadyRaffled = true;
				}
			}

			if (!alreadyRaffled && !Number.isNaN(serverTime)) {
				// console.log(`Current time: ${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()} >>> Showing Raffle: ${currentDraw.gameType} from ${currentDraw.raffleTimestamp}`);
				// Save current Draw and Remove it from the upcoming draw (if exists)
				this.currentDraw = currentDraw;
				this.removeCurrentDrawFromUpcoming(currentDraw);
				if (this.oldDrawsRaffled.length) {
					const lastDrawShowed = this.oldDrawsRaffled[this.oldDrawsRaffled.length - 1];
					previousDraws.unshift(lastDrawShowed);
				}
				this.oldDrawsRaffled.push(currentDraw);

				// Set attribute to current Draw
				const raffleTimestamp = currentDraw.timestamp;
				let currentDrawTime = Date.parse(raffleTimestamp);

				if (isIOS) {
					const newFormat = raffleTimestamp.split(' ').join('T');
					currentDrawTime = Date.parse(newFormat);
				}

				// Validate that the type of game to display is allowed
				const timeIdleMode = (currentDraw.gameType === 7777) ? 92000 : 36000;
				const gameTypeURL = this.store.getters.gameTypeAvailable;
				if ((gameTypeURL && gameTypeURL !== currentDraw.gameType) || ((currentDrawTime + timeIdleMode) < serverTime) || Number.isNaN(currentDrawTime)) {
					return;
				}

				currentDraw.totalBallsToDraw = this.totalBallsByGameType[currentDraw.gameType];
				currentDraw.raffleParseTimestamp = currentDrawTime;

				if ((serverTime - currentDrawTime) <= delayServer) {
					// Set new raffle
					currentDraw.recovery = false;
					this.store.commit('setStartRaffle', currentDraw);
				} else {
					// Start recovery draw
					currentDraw.recovery = true;
					this.store.commit('setStartRecoveryRaffle', currentDraw);
				}

				// Get last raffle
				let lastDraw = null;
				if (this.oldDrawsRaffled.length <= 1) {
					lastDraw = sortedData.shift();
					this.store.commit('setPreviousRaffleData', lastDraw);
				} else {
					lastDraw = this.oldDrawsRaffled[this.oldDrawsRaffled.length - 2];
					this.store.commit('setPreviousRaffleData', lastDraw);
				}
			}
		}
	}

	removeCurrentDrawFromUpcoming(currentDraw) {
		let drawFound = false;

		// Check if draw is on the upcoming ones
		for (let i = this.recentUpcomingDraws.length - 1; i >= 0 && !drawFound; i -= 1) {
			if (this.recentUpcomingDraws[i].gameType === currentDraw.gameType && this.recentUpcomingDraws[i].timestamp === currentDraw.timestamp) {
				this.recentUpcomingDraws.splice(i, 1);
				drawFound = true;
				// Update Store
				this.store.commit('setUpcomingDrawsParsed', this.recentUpcomingDraws);
			}
		}
	}
}
