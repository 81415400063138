<template>
	<div class="w100 h100">
		<v-container class="h100 text-center align-center pt-1 pb-1">
			<v-row no-gutters class="h100">
				<v-col cols="5" class="text-center align-center">
						<div class="titilliumRegularFont">Próximo Sorteio</div>
						<v-img :src="loadImage()" contain></v-img>
				</v-col>
				<v-col cols="7" class="d-flex flex-column align-self-center">
					<div class="textTitle">{{hours}}</div>
					<div class="">{{date}}</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
/* eslint-disable no-console */
import '../css/main.css';
import '../css/general.css';

export default {
	name: 'ProximoSorteio',
	props: ['draw'],
	data() {
		return {
			urlImage: '',
		};
	},
	computed: {
		date() {
			return this.parseDate();
		},
		hours() {
			return this.parseHours();
		},
	},
	methods: {
		loadImage() {
			try {
				this.urlImage = require(`../assets/logos/${this.draw.gameType}.png`);
			} catch (error) {
				this.urlImage = require('../assets/logos/logoVibraIcon.png');
			}

			return this.urlImage;
		},
		parseDate() {
			const dateDraw = this.draw.timestamp;
			let day = new Date(dateDraw).getDate();
			let month = Number(new Date(dateDraw).getMonth() + 1);

			day = (day < 10) ? `0${day}` : day;
			month = (month < 10) ? `0${month}` : month;

			const date = `${day}-${month}-${new Date(dateDraw).getFullYear()}`;
			return date;
		},
		parseHours() {
			const dateDraw = this.draw.timestamp;
			let hour = new Date(dateDraw).getHours();
			let minutes = new Date(dateDraw).getMinutes();

			hour = (hour < 10) ? `0${hour}` : hour;
			minutes = (minutes < 10) ? `0${minutes}` : minutes;

			const hourDraw = `${hour}:${minutes}`;
			return hourDraw;
		},
	},
};
</script>
