<template>
	<v-container class="w100 h100">
		<div class="d-flex text-center pb-2 h100">
			<v-col
				xs="12" sm="9"
				class="h80 pt-0 pb-0 pl-0 pr-0 animate__animated animate__fadeInLeft"
			>
				<IdleApp
					v-if="showIdle"
				/>
				<Keno
					v-if="showDraw && gameType !== 8888"
					v-bind:drawData="newRaffleData"
					:key="keyNewRaffle"
				/>
				<TimeCerto
					v-else-if="showDraw && newRaffleData && gameType === 8888"
					v-bind:drawData="newRaffleData"
					:key="keyNewRaffle"
				/>
			</v-col>
			<v-col cols="3" class="h100 pt-0">
				<div
					class="text-center greyColor mb-1 animate__animated animate__fadeInRightBig"
					style="height: 40%; max-height: 40%"
					v-if="lastRaffleData"
				>
					<UltimoSorteio
						v-if="gameTypeLastRaffle != 8888"
						v-bind:drawData="lastRaffleData"
						:key="keyUltimoSorteo"
					/>
					<UltimoSorteioTimeCerto
						v-else-if="gameTypeLastRaffle === 8888"
						v-bind:drawData="lastRaffleData"
						:key="keyUltimoSorteo"
					/>
				</div>
				<div
					class="text-center greyColor mb-1 animate__animated animate__fadeInRightBig"
					style="height: 20%; max-height: 20%"
					v-for="(draw, index) in nextDrawsData"
					:key="index"
				>
					<ProximoSorteio
						v-bind:draw="draw"
						:key="draw.timestamp"
					/>
				</div>
			</v-col>
		</div>
	</v-container>
</template>

<script>
/* eslint-disable no-console */
import '../css/general.css';
import '../css/main.css';
import '../css/animate.min.css';
import Keno from '../components/keno.vue';
import TimeCerto from '../components/timeCerto.vue';
import ProximoSorteio from '../components/proximoSorteio.vue';
import UltimoSorteio from '../components/ultimoSorteio.vue';
import UltimoSorteioTimeCerto from '../components/ultimoSorteioTimeCerto.vue';
import IdleApp from '../components/Idle.vue';

export default {
	name: 'TvView',
	components: {
		IdleApp,
		Keno,
		TimeCerto,
		ProximoSorteio,
		UltimoSorteio,
		UltimoSorteioTimeCerto,
	},
	data() {
		return {
		};
	},
	computed: {
		nextDrawsData() {
			return this.$store.getters.firstUpcomingDraws;
		},
		lastRaffleData() {
			return this.$store.getters.previousRaffleData;
		},
		newRaffleData() {
			return this.$store.getters.startRaffle;
		},
		gameType() {
			return this.$store.getters.startRaffle.gameType;
		},
		gameTypeLastRaffle() {
			return this.$store.getters.previousRaffleData.gameType;
		},
		showDraw() {
			return this.$store.getters.showDraw;
		},
		showIdle() {
			return (!this.$store.getters.showDraw && !this.$store.getters.startRaffle);
		},
		keyUltimoSorteo() {
			return this.$store.state.previousRaffleData.raffleTimestamp;
		},
		keyNewRaffle() {
			return this.$store.state.startRaffle.raffleTimestamp;
		},
	},
	methods: {
		updateData() {
			// const that = this;
			// const getTime = 10000;

			// this.updateDataEventId = setTimeout(() => {
			// that.getUpcomingDraws();
			// that.getLastDraw();
			//   that.getNewRaffle();

			//   that.updateData();
			// }, getTime);
		},
		/* getUpcomingDraws() {
			this.$store.dispatch('updateUpcomingDraw');
			const data = this.$store.getters.firstUpcomingDraws;
			if (data && data.length) {
				if (!this.nextDrawsData.length || this.nextDrawsData[0].timestamp !== data[0].timestamp) {
					const that = this;
					this.showUpcomingDraws = true;
					this.nextDrawsData = [];
					setTimeout(() => {
						that.nextDrawsData = data;
					}, 500);
				}
			}
		},
		getLastDraw() {
			const data = this.$store.getters.previousRaffleData;
			if (data && data.timestamp) {
				if (!this.lastRaffleData || data.timestamp !== this.lastRaffleData.timestamp) {
					this.showLastDraw = false;
					const that = this;

					setTimeout(() => {
						that.showLastDraw = true;
						that.gameTypeLastRaffle = data.gameType;
						that.lastRaffleData = data;
					}, 50);
				}
			}
		}, */
		/* getNewRaffle() {
			const raffleData = this.$store.state.startRaffle;
			if (raffleData && raffleData.timestamp) {
				if (!this.newRaffleData || (this.newRaffleData.timestamp !== raffleData.timestamp)) {
					this.$store.dispatch('validateRaffle', raffleData.timestamp)
						.then((response) => {
							if (response) {
								this.gameType = raffleData.gameType;
								this.showIdle = false;
								this.showDraw = false;
								this.showAnticipation = true;
								this.newRaffleData = raffleData;
								this.startNewRaffle(raffleData);

								this.$store.dispatch('registerRaffle', raffleData.timestamp);
							}
						})
						.catch((error) => {
							console.log(error);
						});
				}
			}
		}, */
		/* startNewRaffle() {
			const that = this;
			setTimeout(() => {
				that.showAnticipation = false;
				that.showDraw = true;
			}, 5500);
		}, */
	},
};
</script>
