<template>
	<div id="bingoPresenterIdle" class="mainIdleContainer marginAuto colorWhite">
		<div class="idleContainer">
			<div
				class="
				idleText colorBlue marginAuto
				animate__animated
				animate__headShake
				animate__infinite
				animate__slower
				animate__delay-3s"
			>
				Aguarde...
			</div>
		</div>
	</div>
</template>

<script>
import '../css/main.css';
import '../css/animate.min.css';
import '../css/general.css';

export default {
	name: 'IdleApp',
	data() {
		return {
		};
	},
	beforeMount() {
	},
	methods: {
	},
};
</script>
